import React from "react"

import Layout from "../components/layout"
import Footer from "../components/footer"
import LatestBlogPost from "../components/latest-blog-post"

const IndexPage = () => (
  <Layout>
		<div className="wrap">
			<LatestBlogPost/>
		</div>
	<Footer/>

	{/* <!-- Javascript Assets --> */}
	<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
	<script src="/js/plugins-min.js"></script>
	<script src="/js/journal-min.js"></script>
  </Layout>
)

export default IndexPage
