import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
// import PropTypes from 'prop-types';
import LatestBlogPostHeader from './latest-blog-post-header';

class LatestBlogPost extends React.Component {
    render () {
        const { data } = this.props; 
        const latestPost = data.allMarkdownRemark.edges[0].node; 
        return (
            <article className="blog-post">         
                <div className="blog-post__header">
                    <h2 className="blog-post__title">
                        <Link to={`/blog/` + latestPost.frontmatter.path}>
                            {latestPost.frontmatter.title}
                        </Link>
                    </h2>
                    <p className="blog-post__subtitle">
                        {latestPost.frontmatter.date}
                    </p>
                </div>
                <LatestBlogPostHeader />
                <div className="blog-post__content">
                    <p>{latestPost.excerpt}</p>
                    <p>
                        <Link 
                            to={`/blog/` + latestPost.frontmatter.path} 
                            className="button">
                                Read More
                        </Link>
                    </p>
                </div>
            </article>
        )
    }
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, limit: 1) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              frontmatter {
                path
                title
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={data => <LatestBlogPost data={data}/>}
  />
)
