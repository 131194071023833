import React from 'react';
import { Link } from 'gatsby';
import DemoPic from '../images/demo-square.jpg';

export default function LatestBlogPostHeader() {
    return (
        <Link to="/blog/demo-post" className="blog-post__image" 
                style={{"backgroundImage":"url(" + DemoPic + ")"}}>
        </Link>
    )
}
